<template>
  <div class="panel">
    <div class="panel-title" v-if="title!==false">
      <slot name="title"></slot>
    </div>
    <div class="panel-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.panel {
  //background-color: #fff;
  font-size: 13px;
  line-height: 26px;
  font-weight: 400;
  //border-radius: 15px;
  //overflow: hidden;
}

.panel-title {
  line-height: 44px;
  height: 44px;
  //padding: 0 15px;
  font-size: 18px;
  font-weight: 800;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-left: -20px;
  margin-right: -20px;
  background: #fff;
}

.tabs {
  //border: 1px solid #3b5998;
  .panel-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 !important;

    .tab {
      flex: 1 1 auto;
      text-align: center;
      &.active {
        color: #2E447F !important;
        &:first-child{
          border-bottom: 2px solid #415997;
        }
        &:last-child{
          border-bottom: 2px solid #415997;
        }

      }
    }
  }
}

.panel-content {
  //font-size: 24px;
}

.panel-desc {
  padding: 10px 10px 0 10px;
  font-weight: 400;
  color: #434A54;
}
</style>

<script>
export default {
  name: "Panel",
  props: ["title"],
  mounted() {
  }
}
</script>