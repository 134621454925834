<template>
  <div>
    <AppHeader title="课题组成员管理"/>
    <Panel class="tabs search-panel" :title="false">
      <div slot="content">
        <div class="line">
          <input class="line-input mt10" id="keyword" v-model="keyword" placeholder="姓名/手机号/课题组名称"/>
          <div class="flex-dir-row mt10">
            <div class="flex-item-auto">
              <input class="line-input" id="status" @click="showStatus" v-model="statusText" @keydown="preventDefault"
                     placeholder="状态"/>
            </div>
            <div class="btn btn-small green ml5" @click="reset">重设</div>
            <div class="btn btn-small green ml5" @click="initMembers">查询</div>
          </div>
        </div>
      </div>
    </Panel>
    <Panel class="tabs mt15" :title="false">
      <div slot="content" class="status-content" v-infinite-scroll="loadMembers"
           infinite-scroll-disabled="loading"
           infinite-scroll-distance="10">
        <div class="line" v-for="(member,idx) of members" style="position: relative;overflow: hidden">
          <div :class="`member-status ${member.statusClass}`">{{ member.statusText }}</div>

          <div class="flex-dir-row">
            <div class="line-label">课题组名称：</div>
            {{ member.bizObject.name }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">申请人：</div>
            {{ member.user.realName }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">手机号：</div>
            {{ member.user.mobile }}
          </div>

          <div class="flex-dir-row">
            <div class="flex-dir-row flex-item-auto">
              <div class="line-label">申请时间：</div>
              {{ dateFormat(member.createAt) }}
            </div>
            <div class="flex-dir-row">
              <div v-if="member.status==0" class="btn btn-xsmall red" @click="changeStatus(member, 3)">冻结</div>
              <template v-if="member.status==2">
                <div class="btn btn-xsmall green" @click="changeStatus(member, 0)">同意</div>
                <div class="btn btn-xsmall red ml5" @click="changeStatus(member, 3)">拒绝</div>
              </template>
              <div v-if="member.status==3" class="btn btn-xsmall dark-blue" @click="changeStatus(member, 0)">解冻</div>
              <div v-if="member.status==5" class="btn btn-xsmall dark-blue" @click="changeStatus(member, 0)">激活</div>
            </div>
          </div>
        </div>
        <div class="line text-center" v-if="members.length===0">暂无记录</div>
      </div>
    </Panel>

    <mt-datetime-picker
        v-model="dateVal"
        type="datetime"
        @confirm="handleConfirm"
        ref="picker"
    >
    </mt-datetime-picker>

    <mt-popup
        v-model="equipmentsVisible"
        position="bottom"
    >
      <mt-picker :slots="equipmentSlots" @change="onEquipmentChange" value-key="text"
                 v-if="equipmentsVisible"></mt-picker>
    </mt-popup>

    <mt-popup
        v-model="statusVisible"
        position="bottom"
    >
      <mt-picker :slots="statusSlots" @change="onStatusChange" value-key="text"
                 v-if="statusVisible"></mt-picker>
    </mt-popup>

  </div>
</template>

<style lang="scss">
.member-status {
  left: 0;
  top: 0;
  position: absolute;
  font-size: 10px;
  text-align: center;
  color: #fff;
  padding: 0 10px;
  opacity: .9;
  width: 50px;
  height: 20px;
  line-height: 20px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);

  &.member {
    background: #3b5998;
  }

  &.doing {
    background: #8CC152;
  }

  &.cancel {
    background: #434A54;
  }

  &.finish {
    background: #3b5998;
  }
}
</style>

<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {InfiniteScroll, DatetimePicker, Picker, MessageBox, Toast} from 'mint-ui';
import {mapState} from 'vuex'

export default {
  components: {
    AppHeader,
    Panel,
    InfiniteScroll,
    DatetimePicker,
    Picker
  },
  data() {
    return {
      page: 1,
      members: [],
      loading: false,
      noMore: false,
      beginAt: null,
      endAt: null,
      equipment: null,
      equipmentId: null,
      keyword: null,

      dateVal: new Date(),
      pickerField: '',

      equipmentsVisible: false,
      statusVisible: false,

      statusText: '',
      status: -1,
      statusSlots: [{values: [{id: 0, text: '审核通过'}, {id: 2, text: '待审核'}, {id: 5, text: '已拒绝'}]}]
    }
  },
  computed: mapState({
    // 为了能够使用 `this` 获取局部状态，必须使用常规函数
    equipmentSlots(state) {
      return [{values: state.equipments}]
    }
  }),
  methods: {
    onEquipmentChange(picker, values) {
      this.equipment = values[0].text
      this.equipmentId = values[0].id
    },
    onStatusChange(picker, values) {
      this.statusText = values[0].text
      this.status = values[0].id
    },
    showEquipments() {
      this.equipmentsVisible = true
    },
    showStatus() {
      this.statusVisible = true
    },
    handleConfirm(value) {
      if (this.pickerField === 'beginAt') {
        this.beginAt = this.$moment(value).valueOf()
      } else {
        this.endAt = this.$moment(value).valueOf()
      }
    },
    reset() {
      this.page = 1
      this.loading = false
      this.beginAt = null
      this.endAt = null
      this.keyword = ''
      this.equipmentId = ''
      this.status = -1
      this.statusText = ''
    },
    dateFormat(timestamp) {
      if (timestamp) {
        return this.$moment(timestamp).format('YYYY-MM-DD HH:mm')
      }
      return ''
    },
    initMembers() {
      this.members = []
      this.page = 1
      this.noMore = false
      this.loadMembers()
    },
    wrapMember(member) {
      if (member.status === 0) {
        member.statusText = '通过'
        member.statusClass = 'member'
        member.cancelable = true
      } else if (member.status === 2) {
        member.statusText = '待审核'
        member.statusClass = 'doing'
      } else if (member.status === 5) {
        member.statusText = '已拒绝'
        member.statusClass = 'finish'
      }
      return member
    },
    loadMembers() {
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('biz', 3)
      data.append('page', `${this.page}`)
      data.append('limit', '20')
      data.append('orderByBeginAtDesc', 'true')
      this.keyword && data.append('keyword', `${this.keyword}`)
      this.status >= 0 && data.append('status', `${this.status}`)
      //http://localhost:8081/portal/company/query?page=1&limit=10&keyword=&status=&_=1630069536099
      that.$http.post('/ucenter/userRelation/query', data).then(res => {
        if (res.data && res.data.length > 0) {
          const members = res.data.map(lic => {
            return that.wrapMember(lic)
          })
          that.members = that.members.concat(members)
          that.loading = false
          that.page = that.page + 1
        } else {
          this.noMore = true
        }
      })
    },
    preventDefault(e) {
      e.preventDefault()
    },
    changeStatus(member, status) {
      const that = this
      MessageBox.confirm('确定进行此项操作?').then(action => {
        that.$http.post(`/portal/userRelation/save?id=${member.id}&status=${status}`).then(res => {
          that.initMembers()
        })
      })
    }
  },
  mounted() {
    this.initMembers()
  }
}
</script>
